
/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";


.plantilla-ejercicio__div--container {
    /*posicion y layout*/
    width: 100%;
    height: calc(100vh - 80px);
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/;
    /*misc*/

    .plantilla-ejercicio__p--leccion {
        /*posicion y layout*/
        position: relative;
        top: 20px;
        /*modelo de caja*/
        /*tipografia*/
        font-size: 13px;
        text-align: center;
        font-family: 'martel sans', Courier, monospace;
        /*visual*/
        color: $fuenteOpaca;
        /*misc*/
    }

    .plantilla-ejercicio__h2--nombre {
        /*posicion y layout*/
        position: relative;
        /*modelo de caja*/
        /*tipografia*/
        text-align: center;
        font-family: 'martel sans', Courier, monospace;
        /*visual*/
        color: $fuenteOpaca;
        /*misc*/
    }

    .plantilla-ejercicio__p--cantidad-lecciones {
        /*posicion y layout*/
        width: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        /*modelo de caja*/
        padding: 6px 15px;
        margin: auto;
        /*tipografia*/
        font-family: 'martel sans', Courier, monospace;
        /*visual*/
        color: $fuenteOpaca;
        border: solid 1px black;
        border-radius: 9px;
        /*misc*/
    }

    .plantilla__div--container-ejercicio {
        /*posicion y layout*/
        width: 100%;
        position: relative;
        top: 100px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        .plantilla__p--traduce {
            /*posicion y layout*/
            position: relative;
            top: -50px;
            /*modelo de caja*/
            /*tipografia*/
            font-size: 13px;
            text-align: center;
            font-family: 'martel sans', Courier, monospace;
            /*visual*/
            /*misc*/
        }

        .plantilla__p--frase-ejercicio {
            /*posicion y layout*/
            position: relative;
            top: -75px;
            /*modelo de caja*/
            /*tipografia*/
            font-family: 'martel sans', Courier, monospace;
            font-weight: 600;
            /*visual*/
            /*misc*/
        }

        .plantilla__p--aciertos {
            /*posicion y layout*/
            position: relative;
            top: -50px;
            /*modelo de caja*/
            /*tipografia*/
            font-size: 13px;
            text-align: center;
            font-family: 'martel sans', Courier, monospace;
            /*visual*/
            /*misc*/
        }

        .plantilla__p--resolucion-traduccion {
            /*posicion y layout*/
            position: relative;
            top: -75px;
            /*modelo de caja*/
            padding: 3px 20px;
            /*tipografia*/
            font-family: 'martel sans', Courier, monospace;
            font-weight: 600;
            /*visual*/
            color: green;
            border-radius: 15px;
            background-color: #e9e9e9;
            /*misc*/
        }

        @media screen and (min-width: 450px) {
            .plantilla__p--frase-ejercicio {
                /*posicion y layout*/
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                font-size: 20px;
                /*misc*/
            }
        }

        .plantilla__input--respuesta {
            /*posicion y layout*/
            position: relative;
            top: -65px;
            width: 90%;
            height: 30px;
            /*modelo de caja*/
            /*tipografia*/
            font-family: 'martel sans', Courier, monospace;
            font-weight: 600;
            text-align: center;
            /*visual*/
            border-radius: 30px;
            border: 6px solid #625FFF;
            /*misc*/
        }

        .plantilla__input--revisar-traduccion {
            /*posicion y layout*/
            position: relative;
            top: -75px;
            /*modelo de caja*/
            /*tipografia*/
            font-family: 'martel sans', Courier, monospace;
            font-weight: 600;
            /*visual*/
            color: #d30000;
            /*misc*/
        }

        @media screen and (min-width: 450px) {
            .plantilla__input--respuesta {
                /*posicion y layout*/
                width: 440px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }
    }

    .plantilla__div--container-vocabulario {
        /*posicion y layout*/
        position: relative;
        width: 90%;
        height: 45px;
        top: 40px;
        border: #868686 solid 2px;
        /*modelo de caja*/
        margin: auto;
        /*tipografia*/
        font-family: 'martel sans', Courier, monospace;
        /*visual*/
        border-radius: 10px;
        overflow: hidden;
        /*misc*/
        transition: ease-in-out 0.5s;

        p {
            /*posicion y layout*/
            /*modelo de caja*/
            margin: 14px 0;
            /*tipografia*/
            text-align: center;
            font-weight: 600;
            font-size: 20px;
            /*visual*/
            /*misc*/
        }

        ul {
            /*posicion y layout*/
            /*modelo de caja*/
            padding: 0;
            margin: 0;
            /*tipografia*/
            /*visual*/
            /*misc*/
        }

        li {
            /*posicion y layout*/
            width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
            /*modelo de caja*/
            margin: 10px auto;
            /*tipografia*/
            text-align: center;
            /*visual*/
            list-style-type: none;
            border: #625FFF 2px solid;
            border-radius: 10px;
            /*misc*/
        }
    }

    @media screen and (min-width: 320px) {
        .plantilla__div--container-vocabulario {
            /*posicion y layout*/
            width: 320px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }

    //Frase acertada
    .plantilla-ejercicio__div--container-estrellas {
        /*posicion y layout*/
        position: relative;
        width: 250px;
        height: 100px;
        top: 50px;
        /*modelo de caja*/
        margin: auto;
        /*tipografia*/
        /*visual*/
        /*misc*/

        .plantilla-ejercicio__img--estrella-1 {
            /*posicion y layout*/
            position: absolute;
            width: 100px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 5;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
            filter: drop-shadow(0 0 8px #625FFF);
        }

        .plantilla-ejercicio__img--estrella-2 {
            /*posicion y layout*/
            position: absolute;
            top: 15px;
            width: 57px;
            left: 0;
            right: 0;
            margin: auto;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
            filter: drop-shadow(0 0 8px #625FFF);
        }

        .plantilla-ejercicio__img--estrella-3 {
            /*posicion y layout*/
            position: absolute;
            top: 20px;
            width: 45px;
            left: 0;
            right: 0;
            margin: auto;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
            filter: drop-shadow(0 0 8px #625FFF);
        }
    }

    .plantilla-ejercicio__p--traduccion-correcta {
        /*posicion y layout*/
        position: relative;
        top: 40px;
        /*modelo de caja*/
        /*tipografia*/
        font-size: 25px;
        text-align: center;
        font-family: 'martel sans', Courier, monospace;
        font-weight: 600;
        /*visual*/
        /*misc*/
    }

    .plantilla-ejercicio__div--container-resultado {
        /*posicion y layout*/
        position: relative;
        top: 30px;
        width: 100%;
        /*modelo de caja*/
        padding: 5px 0;
        margin: auto;
        /*tipografia*/
        /*visual*/
        background-color: #dddddd;
        border-radius: 20px;
        /*misc*/
    }

    @media screen and (min-width: 320px) {
        .plantilla-ejercicio__div--container-resultado {
            /*posicion y layout*/
            width: 320px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }


    .plantilla-ejercicio__p--frase {
        /*posicion y layout*/
        /*modelo de caja*/
        /*tipografia*/
        font-size: 20px;
        text-align: center;
        font-family: 'martel sans', Courier, monospace;
        font-weight: 600;
        /*visual*/
        /*misc*/
    }

    .plantilla-ejercicio__p--traduccion-traduccion {
        /*posicion y layout*/
        /*modelo de caja*/
        /*tipografia*/
        font-size: 20px;
        text-align: center;
        font-family: 'martel sans', Courier, monospace;
        font-weight: 600;
        /*visual*/
        color: green;
        /*misc*/
    }

    .plantilla-ejercicio__div--siguiente-container {
        /*posicion y layout*/
        position: relative;
        top: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        .plantilla-ejercicio__button--siguiente {
            /*posicion y layout*/
            width: 250px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            /*modelo de caja*/
            /*tipografia*/
            font-size: 20px;
            font-family: 'martel sans', Courier, monospace;
            font-weight: 600;
            /*visual*/
            border-radius: 25px;
            border: #625FFF 2px solid;
            color: $fuenteOpaca;
            /*misc*/
            transition: ease-in-out 0.5s;
            cursor: pointer;
        }

        .plantilla-ejercicio__button--siguiente:hover {
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            background-color: #625FFF;
            color: white;
            /*misc*/
        }
    }

    .plantilla-ejercicio__p--lecciones-recomendadas {
        /*posicion y layout*/
        position: relative;
        top: 18px;
        /*modelo de caja*/
        /*tipografia*/
        font-size: 15px;
        font-family: 'martel sans', Courier, monospace;
        font-weight: 600;
        text-align: center;
        /*visual*/
        /*misc*/
    }

    @media screen and (min-width: 950px) {
        .plantilla-ejercicio__p--lecciones-recomendadas {
            /*posicion y layout*/
            position: relative;
            top: 50px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }

    @media screen and (min-width: 850px) {
        .plantilla-ejercicio__div--container-lecciones-recomendadas {
            /*posicion y layout*/
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }

    @media screen and (min-width: 950px) {
        .plantilla-ejercicio__div--container-lecciones-recomendadas {
            /*posicion y layout*/
            position: relative;
            top: 50px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}

