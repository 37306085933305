/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";

.home__div--container---total {
    /*posicion y layout*/
    width: 100%;
    height: 100vh;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: $fuenteOpaca;
    /*misc*/
}

.home__div--container {
    /*posicion y layout*/
    position: relative;
    width: 100%;
    height: 650px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/


    .home__div--soporte-grua {
        /*posicion y layout*/
        position: absolute;
        bottom: 0px;
        left: 30px;
        width: 40px;
        height: 580px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        border-top-left-radius: 90px;
        background-color: rgb(57, 57, 57);
        /*misc*/
    }

    .home__div--brazo-grua {
        /*posicion y layout*/
        position: absolute;
        top: 90px;
        left: 10px;
        width: 200px;
        height: 20px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        border-radius: 10px;
        background-color: rgb(57, 57, 57);
        /*misc*/
    }

    .home__div--cable {
        /*posicion y layout*/
        position: absolute;
        top: 110px;
        left: 150px;
        width: 10px;
        height: 40px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-color: rgb(80, 80, 80);
        /*misc*/
    }

    .home__div--cable1 {
        /*posicion y layout*/
        left: 150px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-color: rgb(80, 80, 80);
        /*misc*/
    }

    .home__div--cable2 {
        /*posicion y layout*/
        left: 90px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }

    .home__div--letrero {
        /*posicion y layout*/
        position: absolute;
        bottom: 450px;
        left: 12px;
        width: 225px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /*modelo de caja*/
        /*tipografia*/
        font-family: 'martel sans', Courier, monospace;
        /*visual*/
        border-radius: 5px;
        background-color: rgb(43, 43, 43);
        /*misc*/
        -webkit-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.75);

        .home__div--container-frase-en {
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            color: rgb(215, 215, 215);
            /*misc*/

            p {
                /*posicion y layout*/
                padding: 0;
                margin: 0;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }

        .home__div--container-frase-container {
            /*posicion y layout*/
            display: flex;
            justify-content: center;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/

            p {
                /*posicion y layout*/
                padding: 0;
                margin: 0;
                /*modelo de caja*/
                /*tipografia*/
                font-size: 25px;
                /*visual*/
                color: rgb(215, 215, 215);
                /*misc*/
            }

            .home__p--frase-1 {
                /*posicion y layout*/
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
                animation: animacionLetras1 2s ease-in-out infinite;
                animation-delay: 3s
            }

            .home__p--frase-2 {
                /*posicion y layout*/
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
                animation: animacionLetras1 2s ease-in-out infinite;
                animation-delay: 4s
            }

            img {
                /*posicion y layout*/
                width: 24px;
                height: 24px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                opacity: 1 !important;
                /*misc*/
            }
        }
    }

    .home__div--container-pronto {
        /*posicion y layout*/
        position: absolute;
        bottom: 100px;
        right: 10px;
        width: 160px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /*modelo de caja*/
        /*tipografia*/
        font-family: 'martel sans', Courier, monospace;
        /*visual*/
        // background-color: rgb(217, 217, 217);
        /*misc*/

        .home__div--pronto {
            /*posicion y layout*/
            /*modelo de caja*/
            padding: 0;
            margin: 0;
            /*tipografia*/
            font-size: 22px;
            /*visual*/
            color: rgb(215, 215, 215);
            /*misc*/
        }

        .home__div--adorno {
            /*posicion y layout*/
            width: 100%;
            height: 4px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            border-radius: 20px;
            background: linear-gradient(45deg, #110072, #494949, #110072);
            background-size: 400% 400%;
            /*misc*/
            animation: animacionAdornoPronto 2s infinite alternate;
            transition: ease-in-out 2s;
        }
    }


}


@media screen and (min-width: 250px) {
    .home__div--container {
        /*posicion y layout*/
        width: 250px;
        /*modelo de caja*/
        margin: auto;
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}


@keyframes animacionLetras1 {
    0% {
        transform: scale(1);
        color: rgb(215, 215, 215);
    }
    50% {
        transform: scale(1.1);
        color: rgb(124, 124, 247);
    }
    100% {
        transform: scale(1);
        color: rgb(215, 215, 215);
    }
}


@keyframes animacionAdornoPronto {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        color: $fuenteOpaca;
    }
}
