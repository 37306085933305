/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";

.boton-youtube__div--container {
    /*posicion y layout*/
    position: relative;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    margin: 10px auto;
    /*tipografia*/
    /*visual*/
    /*misc*/

    .boton-youtube__button {
        /*posicion y layout*/
        position: relative;
        width: 220px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        /*modelo de caja*/
        /*tipografia*/
        font-family: 'martel sans', Courier;
        font-size: 15px;
        /*visual*/
        background-color: white;
        border: $fuenteOpaca solid 1px;
        border-radius: 15px;
        /*misc*/
        cursor: pointer;
        transition: ease-in-out 0.3s;

        .boton-youtube__img--logo-youtube {
            /*posicion y layout*/
            width: 20px;
            position: absolute;
            right: 15px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }

    .boton-youtube__button:hover {
        /*posicion y layout*/
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-color: #625FFF;
        color: white;
        border: #625FFF solid 1px;
        /*misc*/
    }

    @media screen and (min-width: 850px) {
        .boton-youtube__button {
            /*posicion y layout*/
            width: 250px;
            height: 40px;
            /*modelo de caja*/
            /*tipografia*/
            font-size: 20px;
            /*visual*/
            border-radius: 20px;
            /*misc*/
        }
    }

    @media screen and (min-width: 950px) {
        .boton-youtube__button {
            /*posicion y layout*/
            width: 300px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}