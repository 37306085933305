/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.plantilla-ejercicio__div--container {
  /*posicion y layout*/
  width: 100%;
  height: calc(100vh - 80px);
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__p--leccion {
  /*posicion y layout*/
  position: relative;
  top: 20px;
  /*modelo de caja*/
  /*tipografia*/
  font-size: 13px;
  text-align: center;
  font-family: "martel sans", Courier, monospace;
  /*visual*/
  color: #282828;
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__h2--nombre {
  /*posicion y layout*/
  position: relative;
  /*modelo de caja*/
  /*tipografia*/
  text-align: center;
  font-family: "martel sans", Courier, monospace;
  /*visual*/
  color: #282828;
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__p--cantidad-lecciones {
  /*posicion y layout*/
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /*modelo de caja*/
  padding: 6px 15px;
  margin: auto;
  /*tipografia*/
  font-family: "martel sans", Courier, monospace;
  /*visual*/
  color: #282828;
  border: solid 1px black;
  border-radius: 9px;
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla__div--container-ejercicio {
  /*posicion y layout*/
  width: 100%;
  position: relative;
  top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla__div--container-ejercicio .plantilla__p--traduce {
  /*posicion y layout*/
  position: relative;
  top: -50px;
  /*modelo de caja*/
  /*tipografia*/
  font-size: 13px;
  text-align: center;
  font-family: "martel sans", Courier, monospace;
  /*visual*/
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla__div--container-ejercicio .plantilla__p--frase-ejercicio {
  /*posicion y layout*/
  position: relative;
  top: -75px;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "martel sans", Courier, monospace;
  font-weight: 600;
  /*visual*/
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla__div--container-ejercicio .plantilla__p--aciertos {
  /*posicion y layout*/
  position: relative;
  top: -50px;
  /*modelo de caja*/
  /*tipografia*/
  font-size: 13px;
  text-align: center;
  font-family: "martel sans", Courier, monospace;
  /*visual*/
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla__div--container-ejercicio .plantilla__p--resolucion-traduccion {
  /*posicion y layout*/
  position: relative;
  top: -75px;
  /*modelo de caja*/
  padding: 3px 20px;
  /*tipografia*/
  font-family: "martel sans", Courier, monospace;
  font-weight: 600;
  /*visual*/
  color: green;
  border-radius: 15px;
  background-color: #e9e9e9;
  /*misc*/
}
@media screen and (min-width: 450px) {
  .plantilla-ejercicio__div--container .plantilla__div--container-ejercicio .plantilla__p--frase-ejercicio {
    /*posicion y layout*/
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    font-size: 20px;
    /*misc*/
  }
}
.plantilla-ejercicio__div--container .plantilla__div--container-ejercicio .plantilla__input--respuesta {
  /*posicion y layout*/
  position: relative;
  top: -65px;
  width: 90%;
  height: 30px;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "martel sans", Courier, monospace;
  font-weight: 600;
  text-align: center;
  /*visual*/
  border-radius: 30px;
  border: 6px solid #625FFF;
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla__div--container-ejercicio .plantilla__input--revisar-traduccion {
  /*posicion y layout*/
  position: relative;
  top: -75px;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "martel sans", Courier, monospace;
  font-weight: 600;
  /*visual*/
  color: #d30000;
  /*misc*/
}
@media screen and (min-width: 450px) {
  .plantilla-ejercicio__div--container .plantilla__div--container-ejercicio .plantilla__input--respuesta {
    /*posicion y layout*/
    width: 440px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.plantilla-ejercicio__div--container .plantilla__div--container-vocabulario {
  /*posicion y layout*/
  position: relative;
  width: 90%;
  height: 45px;
  top: 40px;
  border: #868686 solid 2px;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  font-family: "martel sans", Courier, monospace;
  /*visual*/
  border-radius: 10px;
  overflow: hidden;
  /*misc*/
  transition: ease-in-out 0.5s;
}
.plantilla-ejercicio__div--container .plantilla__div--container-vocabulario p {
  /*posicion y layout*/
  /*modelo de caja*/
  margin: 14px 0;
  /*tipografia*/
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  /*visual*/
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla__div--container-vocabulario ul {
  /*posicion y layout*/
  /*modelo de caja*/
  padding: 0;
  margin: 0;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla__div--container-vocabulario li {
  /*posicion y layout*/
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  margin: 10px auto;
  /*tipografia*/
  text-align: center;
  /*visual*/
  list-style-type: none;
  border: #625FFF 2px solid;
  border-radius: 10px;
  /*misc*/
}
@media screen and (min-width: 320px) {
  .plantilla-ejercicio__div--container .plantilla__div--container-vocabulario {
    /*posicion y layout*/
    width: 320px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__div--container-estrellas {
  /*posicion y layout*/
  position: relative;
  width: 250px;
  height: 100px;
  top: 50px;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__div--container-estrellas .plantilla-ejercicio__img--estrella-1 {
  /*posicion y layout*/
  position: absolute;
  width: 100px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 5;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  filter: drop-shadow(0 0 8px #625FFF);
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__div--container-estrellas .plantilla-ejercicio__img--estrella-2 {
  /*posicion y layout*/
  position: absolute;
  top: 15px;
  width: 57px;
  left: 0;
  right: 0;
  margin: auto;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  filter: drop-shadow(0 0 8px #625FFF);
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__div--container-estrellas .plantilla-ejercicio__img--estrella-3 {
  /*posicion y layout*/
  position: absolute;
  top: 20px;
  width: 45px;
  left: 0;
  right: 0;
  margin: auto;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  filter: drop-shadow(0 0 8px #625FFF);
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__p--traduccion-correcta {
  /*posicion y layout*/
  position: relative;
  top: 40px;
  /*modelo de caja*/
  /*tipografia*/
  font-size: 25px;
  text-align: center;
  font-family: "martel sans", Courier, monospace;
  font-weight: 600;
  /*visual*/
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__div--container-resultado {
  /*posicion y layout*/
  position: relative;
  top: 30px;
  width: 100%;
  /*modelo de caja*/
  padding: 5px 0;
  margin: auto;
  /*tipografia*/
  /*visual*/
  background-color: #dddddd;
  border-radius: 20px;
  /*misc*/
}
@media screen and (min-width: 320px) {
  .plantilla-ejercicio__div--container .plantilla-ejercicio__div--container-resultado {
    /*posicion y layout*/
    width: 320px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__p--frase {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  font-size: 20px;
  text-align: center;
  font-family: "martel sans", Courier, monospace;
  font-weight: 600;
  /*visual*/
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__p--traduccion-traduccion {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  font-size: 20px;
  text-align: center;
  font-family: "martel sans", Courier, monospace;
  font-weight: 600;
  /*visual*/
  color: green;
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__div--siguiente-container {
  /*posicion y layout*/
  position: relative;
  top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__div--siguiente-container .plantilla-ejercicio__button--siguiente {
  /*posicion y layout*/
  width: 250px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  font-size: 20px;
  font-family: "martel sans", Courier, monospace;
  font-weight: 600;
  /*visual*/
  border-radius: 25px;
  border: #625FFF 2px solid;
  color: #282828;
  /*misc*/
  transition: ease-in-out 0.5s;
  cursor: pointer;
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__div--siguiente-container .plantilla-ejercicio__button--siguiente:hover {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #625FFF;
  color: white;
  /*misc*/
}
.plantilla-ejercicio__div--container .plantilla-ejercicio__p--lecciones-recomendadas {
  /*posicion y layout*/
  position: relative;
  top: 18px;
  /*modelo de caja*/
  /*tipografia*/
  font-size: 15px;
  font-family: "martel sans", Courier, monospace;
  font-weight: 600;
  text-align: center;
  /*visual*/
  /*misc*/
}
@media screen and (min-width: 950px) {
  .plantilla-ejercicio__div--container .plantilla-ejercicio__p--lecciones-recomendadas {
    /*posicion y layout*/
    position: relative;
    top: 50px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 850px) {
  .plantilla-ejercicio__div--container .plantilla-ejercicio__div--container-lecciones-recomendadas {
    /*posicion y layout*/
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 950px) {
  .plantilla-ejercicio__div--container .plantilla-ejercicio__div--container-lecciones-recomendadas {
    /*posicion y layout*/
    position: relative;
    top: 50px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.boton-youtube__div--container {
  /*posicion y layout*/
  position: relative;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  margin: 10px auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.boton-youtube__div--container .boton-youtube__button {
  /*posicion y layout*/
  position: relative;
  width: 220px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "martel sans", Courier;
  font-size: 15px;
  /*visual*/
  background-color: white;
  border: #282828 solid 1px;
  border-radius: 15px;
  /*misc*/
  cursor: pointer;
  transition: ease-in-out 0.3s;
}
.boton-youtube__div--container .boton-youtube__button .boton-youtube__img--logo-youtube {
  /*posicion y layout*/
  width: 20px;
  position: absolute;
  right: 15px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.boton-youtube__div--container .boton-youtube__button:hover {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #625FFF;
  color: white;
  border: #625FFF solid 1px;
  /*misc*/
}
@media screen and (min-width: 850px) {
  .boton-youtube__div--container .boton-youtube__button {
    /*posicion y layout*/
    width: 250px;
    height: 40px;
    /*modelo de caja*/
    /*tipografia*/
    font-size: 20px;
    /*visual*/
    border-radius: 20px;
    /*misc*/
  }
}
@media screen and (min-width: 950px) {
  .boton-youtube__div--container .boton-youtube__button {
    /*posicion y layout*/
    width: 300px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.home__div--container---total {
  /*posicion y layout*/
  width: 100%;
  height: 100vh;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #282828;
  /*misc*/
}

.home__div--container {
  /*posicion y layout*/
  position: relative;
  width: 100%;
  height: 650px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.home__div--container .home__div--soporte-grua {
  /*posicion y layout*/
  position: absolute;
  bottom: 0px;
  left: 30px;
  width: 40px;
  height: 580px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  border-top-left-radius: 90px;
  background-color: rgb(57, 57, 57);
  /*misc*/
}
.home__div--container .home__div--brazo-grua {
  /*posicion y layout*/
  position: absolute;
  top: 90px;
  left: 10px;
  width: 200px;
  height: 20px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  border-radius: 10px;
  background-color: rgb(57, 57, 57);
  /*misc*/
}
.home__div--container .home__div--cable {
  /*posicion y layout*/
  position: absolute;
  top: 110px;
  left: 150px;
  width: 10px;
  height: 40px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: rgb(80, 80, 80);
  /*misc*/
}
.home__div--container .home__div--cable1 {
  /*posicion y layout*/
  left: 150px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: rgb(80, 80, 80);
  /*misc*/
}
.home__div--container .home__div--cable2 {
  /*posicion y layout*/
  left: 90px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.home__div--container .home__div--letrero {
  /*posicion y layout*/
  position: absolute;
  bottom: 450px;
  left: 12px;
  width: 225px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "martel sans", Courier, monospace;
  /*visual*/
  border-radius: 5px;
  background-color: rgb(43, 43, 43);
  /*misc*/
  -webkit-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.75);
}
.home__div--container .home__div--letrero .home__div--container-frase-en {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  color: rgb(215, 215, 215);
  /*misc*/
}
.home__div--container .home__div--letrero .home__div--container-frase-en p {
  /*posicion y layout*/
  padding: 0;
  margin: 0;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.home__div--container .home__div--letrero .home__div--container-frase-container {
  /*posicion y layout*/
  display: flex;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.home__div--container .home__div--letrero .home__div--container-frase-container p {
  /*posicion y layout*/
  padding: 0;
  margin: 0;
  /*modelo de caja*/
  /*tipografia*/
  font-size: 25px;
  /*visual*/
  color: rgb(215, 215, 215);
  /*misc*/
}
.home__div--container .home__div--letrero .home__div--container-frase-container .home__p--frase-1 {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  animation: animacionLetras1 2s ease-in-out infinite;
  animation-delay: 3s;
}
.home__div--container .home__div--letrero .home__div--container-frase-container .home__p--frase-2 {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  animation: animacionLetras1 2s ease-in-out infinite;
  animation-delay: 4s;
}
.home__div--container .home__div--letrero .home__div--container-frase-container img {
  /*posicion y layout*/
  width: 24px;
  height: 24px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  opacity: 1 !important;
  /*misc*/
}
.home__div--container .home__div--container-pronto {
  /*posicion y layout*/
  position: absolute;
  bottom: 100px;
  right: 10px;
  width: 160px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "martel sans", Courier, monospace;
  /*visual*/
  /*misc*/
}
.home__div--container .home__div--container-pronto .home__div--pronto {
  /*posicion y layout*/
  /*modelo de caja*/
  padding: 0;
  margin: 0;
  /*tipografia*/
  font-size: 22px;
  /*visual*/
  color: rgb(215, 215, 215);
  /*misc*/
}
.home__div--container .home__div--container-pronto .home__div--adorno {
  /*posicion y layout*/
  width: 100%;
  height: 4px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  border-radius: 20px;
  background: linear-gradient(45deg, #110072, #494949, #110072);
  background-size: 400% 400%;
  /*misc*/
  animation: animacionAdornoPronto 2s infinite alternate;
  transition: ease-in-out 2s;
}

@media screen and (min-width: 250px) {
  .home__div--container {
    /*posicion y layout*/
    width: 250px;
    /*modelo de caja*/
    margin: auto;
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@keyframes animacionLetras1 {
  0% {
    transform: scale(1);
    color: rgb(215, 215, 215);
  }
  50% {
    transform: scale(1.1);
    color: rgb(124, 124, 247);
  }
  100% {
    transform: scale(1);
    color: rgb(215, 215, 215);
  }
}
@keyframes animacionAdornoPronto {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    color: #282828;
  }
}

